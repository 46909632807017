export default class ChatForm {
	constructor(opts) {
		this.$wrapper = opts.$wrapper;
		this.profile = opts.profile;
		this.setup();
	}

	setup() {
		this.$chat_form = $(document.createElement("div"));
		this.$chat_form.addClass("chat-form");
		this.setup_header();
		this.setup_form();
	}

	setup_header() {
		this.avatar_html = frappe.avatar(null, "avatar-medium", this.profile.name);
		const header_html = `
			<div class='chat-header mb-2'>
				${this.avatar_html}
				<div class='chat-profile-info'>
					<div class='chat-profile-name'>
						${__(this.profile.name)}
						<div class='online-circle'></div>
					</div>
					<div class='chat-profile-status'>${__("Typically replies in a few hours")}</div>
				</div>
			</div>
		`;
		this.$chat_form.append(header_html);
	}

	setup_form() {
		const form_html = `
			<div class='chat-form-container'>
				<p class='chat-query-heading'>${__("Share your queries or comments here.")}</p>
				<form>
					<div class='form-group'>
						<label class='form-label'>${__("Subject")}</label>
						<input type='text' class='form-control' id='chat-fullname'
							placeholder='${__("Please enter your full name")}'>
					</div>
					<div class='form-group'>
						<label class='form-label'>${__("Email Address")}</label>
						<input type='email' class='form-control' id='chat-email'
							placeholder='${__("Please enter your email")}'>
					</div>
					<div class='form-group'>
						<label class='form-label'>${__("Message")}</label>
						<textarea class='form-control' id='chat-message-area'
							placeholder='${__("Please enter your message")}'></textarea>
					</div>
					<button type='button' class='btn btn-primary w-100' id='submit-form'>
						${__("Create Ticket")}
					</button>
				</form>
			</div>
		`;
		const footer_html = `
	  <a class='chat-footer' target='_blank' href='https://frappeframework.com/'>
		${__("⚡ Powered by Frappe")}
	  </a>
	`;
		this.$chat_form.append(form_html + footer_html);
	}

	get_values() {
		const result = {
			email: $("#chat-email").val(),
			full_name: $("#chat-fullname").val(),
			message: $("#chat-message-area").val(),
		};
		return result;
	}

	async validate_form() {
		try {
			const form_values = this.get_values();
			const address = await this.getAddress();
			this.create_issue(form_values, address);
		} catch (error) {
			//pass
		}
	}

	create_issue(form_values, address) {
		this.$chat_form.parents(".chat-app").hide();
		frappe.call({
			method: "examroom.examroom.hook_events.issue.create_issue",
			args: {
				raised_by: form_values.email,
				description: form_values.message,
				subject: form_values.full_name,
				address: address,
			},
			callback: function (r) {
				frappe.show_alert(
					{
						message: __(`Ticket ${r.message.name} has been created`),
						indicator: "green",
					},
					10
				);
				setTimeout(function () {
					window.location.reload();
				}, 2000);
			},
		});
	}

	render() {
		this.$wrapper.html(this.$chat_form);
		const me = this;
		$("#submit-form").on("click", function () {
			me.validate_form();
		});
	}

	async getAddress() {
		try {
			const position = await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			});
			const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}&zoom=18&addressdetails=1`;
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Error fetching address: ${response.status}`);
			}
			const data = await response.json();
			return data["display_name"];
		} catch (error) {
			console.error("Error:", error);
			throw new Error("Failed to retrieve address");
		}
	}
}
